<script setup lang="ts">
import PlantsBannerPrimary from '~/assets/images/banner/plants_banner_primary.svg'
import PlantsBannerSecondary from '~/assets/images/banner/plants_banner_secondary.svg'
import BannerCorner from '~/assets/images/banner/banner_corner.svg'

const props = withDefaults(defineProps<{
  title: string
  description?: string
  primaryButtonText?: string
  primaryButtonLink?: string
  secondaryButtonText?: string
  secondaryButtonLink?: string
  image?: string
  variant: 'primary' | 'secondary'
}>(), {
  variant: 'primary',
})

const bannerImage = computed(() => {
  if (props.variant === 'primary')
    return PlantsBannerPrimary
  return PlantsBannerSecondary
})

const isBannerClosed = ref(false)
</script>

<template>
  <div v-if="!isBannerClosed" class="bg-primary relative overflow-hidden flex flex-col">
    <div class="absolute right-6 top-6">
      <!-- <button class="h-8 w-8 p-2 rounded hover:bg-secondary/50 duration-200" @click="isBannerClosed = true">
        <img src="~/assets/images/icons/close-white.svg" alt="Close">
      </button> -->
    </div>
    <img class=" absolute left-0 bottom-0 object-contain" :src="bannerImage" alt="">
    <div class="container mx-auto flex items-center w-full justify-center">
      <div
        class=" px-4 grid h-full gap-12 z-10 py-8" :class="{
          'md:grid-cols-[1fr_2fr]': props.image,
          'md:grid-cols-[1fr]': !props.image,
        }"
      >
        <div v-if="props.image" class="relative max-h-40">
          <img class="flex-none w-full object-cover rounded-lg h-full" :src="props.image" alt="">
          <img class="absolute top-0 right-0 h-full w-6 max-h-max -translate-y-1/3 translate-x-1/3 object-contain" :src="BannerCorner" alt="">
        </div>
        <div class="h-full flex-none max-w-max">
          <div class="text-white flex-1 h-full flex flex-col gap-2">
            <h3
              class="text-2xl font-bold text-shadow"
            >
              {{ props.title }}
            </h3>
            <p
              class="text-lg text-shadow max-w-60ch"
              style=""
            >
              {{ props.description }}
            </p>
            <div class="mt-2">
              <NuxtLinkLocale v-if="primaryButtonLink && primaryButtonText" :to="primaryButtonLink" class="bg-secondary text-white rounded-xl min-w-80 px-8 py-3">
                {{ primaryButtonText }}
              </NuxtLinkLocale>
              <NuxtLinkLocale v-if="secondaryButtonLink && secondaryButtonText" :to="secondaryButtonLink" class="bg-white text-black rounded-xl min-w-80 px-8 py-3">
                {{ secondaryButtonText }}
              </NuxtLinkLocale>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media (max-width: 768px) {
  .text-shadow {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }

}
@media screen {
}
</style>
